import React from 'react';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { accountApi } from '../../lib/api/account';
import { PermissionType } from '../../store/pageFrame/types';
import { isCurrentDatePastExpDate } from '../../common/utils';
import { useDS3Trial } from './use-ds3-trial';
import { successToast, errorToast } from '../../store/pageFrame/actions';
import useUserInfo from '../../auth/useUserInfo';
import { launchDarklyFlags } from '../../common/launchDarkly';

export function useDS3Paywall() {
  const userInfo = useUserInfo();
  const { flags } = launchDarklyFlags();
  const isStandaloneUser = userInfo?.subscriberId === 'undefined' || !userInfo?.subscriberId;
  const subscription = useGetUserSubscription();
  const subscriptionRequired = flags['subscription-required'] as boolean;
  const subscriptionEndDate = subscription.data?.validUntil.slice(0, 10);

  const memberships = useGetHasDs3Membership();
  const permissions = memberships.data as string[];

  // TODO: Once trial management is moved to accounts-api, we’ll need to rewrite validSubscription logic to align with the new API structure.
  const validSubscription = permissions?.includes(PermissionType.DS3);
  const validSubscriptionExpired =
    isValidUntilSet(subscriptionEndDate) && isCurrentDatePastExpDate(subscriptionEndDate ?? '');
  const isPrimeUserWithDs3Access = !isStandaloneUser && permissions?.includes(PermissionType.DS3);
  const isFormsStandaloneUserWithDs3Access =
    isStandaloneUser && permissions?.some((p) => p === PermissionType.DS3 || p === PermissionType.FORMS);

  const hasPaidForDS3 =
    !subscriptionRequired || isPrimeUserWithDs3Access || isFormsStandaloneUserWithDs3Access || validSubscription;

  const trial = useDS3Trial({
    hasPaidForDS3,
    isLoadingOrError: subscription.isLoading || memberships.isLoading || !userInfo || memberships.isError,
  });

  const isLoading = subscription.isLoading || memberships.isLoading || trial.isLoading || !userInfo;
  const isAllowedDS3Access = isLoading || hasPaidForDS3 || !!trial.daysLeft;
  const isExpired =
    validSubscriptionExpired ||
    (trial.isExpired && (!trial.latestTrialExtension || trial.latestTrialExtension.envelopesLeft === 0));

  return React.useMemo(() => ({ isAllowedDS3Access, isLoading, isExpired, trial, validSubscription }), [
    isAllowedDS3Access,
    isLoading,
    isExpired,
    trial,
    validSubscription,
  ]);
}

function useGetUserSubscription() {
  return useQuery(['get-user-subscription'], () => accountApi.getUserSubscription().then((res) => res?.result), {
    onSuccess() {
      const params = new URLSearchParams(window.location.search);
      const fromPaymentSuccess = params.has('paymentSuccess');

      if (fromPaymentSuccess) {
        successToast('Your subscription has been successfully updated!');
      }
    },
    onError() {
      errorToast('We were unable to validate your subscription, please try again or reach out to support');
    },
  });
}

function useGetHasDs3Membership(options?: UseQueryOptions) {
  return useQuery(
    ['user-get-users-memberships'],
    async () => {
      const memberships = await accountApi.getUsersMemberships();
      if ('errors' in memberships) {
        throw new Error(memberships.errors);
      }
      return memberships.permissions;
    },
    options
  );
}

function isValidUntilSet(validUntil: string | undefined) {
  return !!validUntil && validUntil !== '0001-01-01';
}
