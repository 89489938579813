import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { SecureRoute, LoginCallback, PrimeAuthLoginCallback } from '@skyslope/auth-react';
import SenderBuild from './containers/SenderBuild';
import TemplateTagging from './containers/TemplateTagging';
import TemplateCreate from './containers/TemplateCreate';
import TemplateManagement from './containers/TemplateManagement';
import EnvelopeManagement from './containers/EnvelopeManagement';
import EnvelopeHistory from './containers/EnvelopeHistory';
import RecipientManagement from './containers/RecipientManagement';
import ApplyTemplate from './containers/ApplyTemplate';
import EnvelopeDocuments from './containers/EnvelopeDocuments';
import { ResourceNames } from './lib/constants';
import Logout from './containers/Logout';
import LoggedOut from './containers/LoggedOut';
import UserPreferences from './containers/UserPreferences';
import Login from './components/senderBuild/Login';
import TemplateShare from './components/templateSharing/TemplateShare';
import NotFound from './common/errorPages/NotFound';
import Properties from './dtm/containers/Properties';
import Documents from './dtm/containers/Documents';
import Recipients from './dtm/containers/Recipients';
import SubscriptionPage from './components/pageFrame/SubscriptionPage';
import { CouponRedirect } from './containers/CouponRedirect';

export default function App() {
  return (
    <Switch>
      <Redirect from="/envelope/:id" to="/envelopes/:id" exact />
      <Route path="/" component={Login} exact />
      <SecureRoute path="/envelopes" component={EnvelopeManagement} exact />
      <SecureRoute path="/envelopes/:id/history" component={EnvelopeHistory} exact />
      <SecureRoute
        path="/envelopes/:id"
        render={(props) => <SenderBuild {...props} resource={ResourceNames.ENVELOPE} />}
        exact
      />
      <SecureRoute path="/envelopes/:id/recipients" component={RecipientManagement} exact />
      <SecureRoute path="/envelopes/:id/apply-template" component={ApplyTemplate} exact />
      <SecureRoute path="/envelopes/:id/documents" component={EnvelopeDocuments} exact />
      <SecureRoute path="/subscriptions" component={SubscriptionPage} exact />
      <SecureRoute path="/dtm/properties" component={Properties} exact />
      <SecureRoute path="/dtm/envelopes/:id/properties" component={Properties} exact />
      <SecureRoute path="/dtm/envelopes/:id/documents" component={Documents} exact />
      <SecureRoute path="/dtm/envelopes/:id/recipients" component={Recipients} exact />
      <SecureRoute path="/preferences" component={UserPreferences} exact />
      <Route path="/auth/callback" component={LoginCallback} exact />
      <Route path="/auth/callback/prime" component={PrimeAuthLoginCallback} exact />
      <SecureRoute path="/logout" component={Logout} exact />
      <Route path="/logged_out" component={LoggedOut} exact />

      {/* Templates */}
      <SecureRoute path="/templates/:id/info" component={TemplateTagging} exact />
      <SecureRoute path="/templates/:id/create" component={TemplateCreate} exact />
      <SecureRoute path="/templates/:id/share" render={(props) => <TemplateShare {...props} />} exact />
      <SecureRoute path="/templates" component={TemplateManagement} exact />
      <Route path="/templates/auth/callback" component={LoginCallback} />
      <SecureRoute path="/templates/logout" component={Logout} exact />
      <Route path="/templates/logged_out" component={LoggedOut} exact />
      <SecureRoute
        path="/templates/:id"
        render={(props) => <SenderBuild {...props} resource={ResourceNames.TEMPLATE} />}
        exact
      />
      <SecureRoute path="/coupon-redirect" component={CouponRedirect} />

      <Route path="*" component={NotFound} />
    </Switch>
  );
}
