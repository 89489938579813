import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDS3Paywall } from '@digisign3-ui/sender/src/hooks/paywall/use-ds3-paywall';
import SavingOverlay from '@digisign3-ui/sender/src/components/senderBuild/SavingOverlay';
import { useDispatch } from 'react-redux';
import { errorToast } from '@digisign3-ui/sender/src/store/pageFrame/actions';

/**
 * Accepts a coupon URL for a Stripe discount page and redirects the user to that page if they do not have an active subscription.
 */
export const CouponRedirect = () => {
  const dispatch = useDispatch();
  const paywall = useDS3Paywall();
  const { isLoading, validSubscription } = paywall;
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const url = queryParams.get('url');

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      dispatch(errorToast('Something went wrong. Please try again.'));
      window.location.assign('/envelopes');
    }, 5000);

    if (url && !isLoading) {
      clearTimeout(timeoutId); // Clear the timeout if redirecting

      if (validSubscription) {
        // User already has a valid subscription, should not see subscription page
        dispatch(errorToast('This account already has a valid subscription and cannot apply this coupon.'));
        window.location.assign('/envelopes');
      } else {
        // Send them to coupon page
        window.location.assign(url);
      }
    }

    return () => clearTimeout(timeoutId);
  }, [location.search, url, paywall, dispatch]);

  return <SavingOverlay />;
};
