import React from 'react';
import { Redirect } from 'react-router-dom';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { colors } from '@skyslope/mache';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { useAuth } from '@skyslope/auth-react';
import login from '../../images/login.svg';
import logo from '../../images/logo.svg';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    flexDirection: 'row',
    width: '100%',
    height: '100%',
    alignItems: 'center',
  },
  buttonRoot: {
    width: '100%',
    borderRadius: 30,
  },
  fnfText: {
    color: colors.grey[800],
    fontSize: '19px',
    marginTop: '14px',
    marginBottom: '34px',
  },
  footer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginBottom: '-500px',
  },
  footerText: {
    fontSize: '14px',
    '&:last-child': {
      marginTop: '10px',
    },
  },
  loginArea: {
    textAlign: 'center',
    background: '#fff',
    flex: 1,
    height: '100%',
    maxWidth: '55%',
    display: 'grid',
    placeContent: 'center',
    alignItems: 'center',
  },
  loginAreaResponsive: {
    maxWidth: '100%',
  },
  loginContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  loginHeader: {
    color: colors.grey[800],
    fontSize: '30px',
    marginTop: '10px',
  },
  loginHeaderResponsive: {
    color: colors.grey[800],
    fontSize: '25px',
    marginTop: '10px',
  },
  loginWrapper: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: '384px',
  },
  logoArea: {
    background: colors.blue[800],
    color: '#fff',
    height: '100%',
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    maxWidth: '45%',
  },
  logoAreaResponsive: {
    display: 'none',
  },
  logoContainer: {
    padding: '20px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  logoText: {
    color: '#fff',
    paddingTop: '60px',
    fontSize: '30px',
  },
  logoSubtext: {
    color: '#fff',
    paddingRight: '20px',
    paddingTop: '28px',
    fontSize: '19px',
    fontWeight: 'normal',
  },
  logoImage: {
    height: 180,
    width: 220,
    alignSelf: 'start',
  },
  mobilePadding: {
    padding: '0px 24px 24px',
  },
}));

const Login = () => {
  const isMdScreen = useMediaQuery('(max-width: 1070px)');
  const isSmScreen = useMediaQuery('(max-width: 600px)');
  const { authState } = useAuth();
  const classes = useStyles();
  const loginAreaClasses = isMdScreen ? `${classes.loginArea} ${classes.loginAreaResponsive}` : classes.loginArea;
  const logoAreaClasses = isMdScreen ? classes.logoAreaResponsive : classes.logoArea;
  const tokenInStorage = sessionStorage.getItem('okta-token-storage');
  const authSessionInfo = JSON.stringify(tokenInStorage);

  if (authState.isAuthenticated) {
    return <Redirect to="/envelopes" />;
  }

  if (authSessionInfo.length >= 5) {
    return null;
  }

  return (
    <div className={classes.root}>
      <div className={loginAreaClasses}>
        <div className={classes.loginWrapper}>
          <div className={isSmScreen ? `${classes.loginContainer} ${classes.mobilePadding}` : classes.loginContainer}>
            <img src={logo} tabIndex={1} alt="Skyslope DigiSign" />
            <Divider style={{ margin: '32px 0', width: '100%' }} />
            <Typography
              variant="body2"
              className={isSmScreen ? classes.loginHeaderResponsive : classes.loginHeader}
              tabIndex={2}
            >
              Welcome back to DigiSign.
            </Typography>
            <Typography className={classes.fnfText} tabIndex={2}>
              Sign in with FNF to continue.
            </Typography>
            <Button
              classes={{ root: classes.buttonRoot }}
              tabIndex={4}
              variant="contained"
              color="primary"
              endIcon={<ArrowForwardIcon />}
              onClick={() => {
                window.location.href = '/envelopes?idp=fnf';
              }}
            >
              Sign in
            </Button>
            <Typography tabIndex={5} variant="body1" style={{ marginTop: '40px', fontSize: '16px' }}>
              Or, sign in with your{' '}
              <a style={{ textDecoration: 'none', color: colors.blue[800] }} tabIndex={7} href="/envelopes?idp=prime">
                SkySlope account
              </a>{' '}
              instead.
            </Typography>
          </div>
        </div>
        <div className={isSmScreen ? `${classes.footer} ${classes.mobilePadding}` : classes.footer}>
          <Typography tabIndex={5} variant="body1" className={classes.footerText}>
            Need help signing in?
          </Typography>
          <Typography tabIndex={6} variant="body1" className={classes.footerText}>
            Reach out to{' '}
            <a
              style={{ textDecoration: 'none', color: colors.blue[800] }}
              tabIndex={7}
              href="https://support.skyslope.com/hc/en-us"
            >
              SkySlope support
            </a>{' '}
            for help.
          </Typography>
        </div>
      </div>
      <div className={logoAreaClasses}>
        <div className={classes.logoContainer}>
          <img className={classes.logoImage} src={login} tabIndex={8} alt="Signing paper" />
          <Typography tabIndex={9} variant="h1" className={classes.logoText}>
            Seal the deal quickly, <br /> conveniently, and securely
          </Typography>
          <Typography tabIndex={10} variant="h5" className={classes.logoSubtext}>
            Send documents for signature from virtually <br /> anywhere at any time to anyone.
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default Login;
