import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';
import { SmartEmailContextProvider, useSmartEmailContext } from '@digisign3-ui/sender/src/context/smart-email-context';
import EmailBodyLoading from '@digisign3-ui/sender/src/components/senderBuild/EmailBodyLoading';
import { generateEmailSubject, generateEmailBody } from '../../lib/utils';
import { IBlocks, IEmailConfig, IGroups } from '../../store/senderBuild/types';
import { IRootState } from '../../store';
import { useShallowSelector, useDispatch } from '../../lib/reduxHooks';
import { focusInput, blurInput, validateBlocksBeforeSending, updateEmailConfig } from '../../store/senderBuild/actions';
import { EMAIL_BODY_LENGTH, EMAIL_SUBJECT_LENGTH } from '../../lib/constants';
import validateBlocksAndSendEnvelope from '../../lib/validateBlocksAndSendEnvelope';
import { isMediumScreenMediaQuery } from '../../lib/isSmallScreen';
import { isSkySlopeMobileApp, postWindowMessage } from '../../common/utils';
import SmartEmail from './SmartEmail';
import { LaunchDarklyFlags } from '../../common/launchDarkly';
import { useUpdateEnvelopesLeft } from '../../hooks/paywall/use-ds3-trial';

const useStyles = makeStyles(() => ({
  mobileTitle: {
    fontFamily: 'proxima-nova, sans-serif !important',
  },
  closeButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    minHeight: '56px',
  },
  subject: {
    fontSize: '22px',
    height: '57px',
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '24.5px',
    marginBottom: '24.5px',
  },
  mobileButtonWrapper: {
    display: 'flex',
    justifyContent: 'center',
    bottom: 0,
    position: 'fixed',
    width: '100%',
    left: 0,
    alignItems: 'center',
    padding: '15px 0',
    borderTop: '1px solid #CAD5E0',
    backgroundColor: '#FFF',
  },
  inputContent: {
    width: '100%',
    paddingBottom: '24px',
  },
  helperText: {
    textAlign: 'right',
    backgroundColor: '#F5F7F9',
    marginTop: 0,
  },
}));

const emailSettingsSelector = (state: IRootState) => ({
  emailConfig: state.senderBuild.emailConfig,
  id: state.senderBuild.id,
  blocks: state.senderBuild.blocks,
  blockGroups: state.senderBuild.groups,
  zoom: state.senderBuild.zoom,
  hasAttachment: state.pageFrame.settings?.user?.emailDocsAsAttachment,
});

interface IEmailSettingsState {
  emailConfig: IEmailConfig;
  id: string;
  blocks: IBlocks;
  blockGroups: IGroups;
  zoom: number;
  hasAttachment: boolean;
}

// we're not using most of the props specifically for isMobileApp
interface IProps {
  fullScreen: boolean;
  toggleModal?: () => void;
  subject?: string;
  action?: string;
  send?: boolean;
  handleBackButtonTap?: () => void;
  sendEnvelopeForMobileApp?: (emailConfig: {}) => void;
  flags?: LaunchDarklyFlags;
  envelopeId: string;
}

export type SmartEmailConfig = {
  tone: string;
  body: string;
  envelopeId: string;
};

const ActionButtons = ({
  toggleModal,
  saveEmailConfig,
  emailConfig,
  action,
  fullScreen,
}: {
  toggleModal: any;
  saveEmailConfig: any;
  emailConfig: IEmailConfig;
  action: any;
  fullScreen: boolean;
}) => {
  const classes = useStyles();
  return (
    <div className={fullScreen ? classes.mobileButtonWrapper : classes.buttonWrapper}>
      <Button
        data-spec="cancel"
        variant={fullScreen ? 'outlined' : 'text'}
        style={{ marginRight: fullScreen ? '16px' : '24px', width: fullScreen ? '140px' : '' }}
        color="primary"
        onClick={toggleModal}
      >
        {fullScreen ? 'Back' : 'Cancel'}
      </Button>
      <Button
        data-spec="saveButton"
        variant="contained"
        color="primary"
        onClick={saveEmailConfig}
        disabled={emailConfig.loading}
        style={{ width: fullScreen ? '140px' : '' }}
      >
        {action}
      </Button>
    </div>
  );
};

const handleCharacterLimit = (e: any, inputCharacterLimit: number) => {
  const { clipboardData } = e;
  if (clipboardData) {
    if (e.target.value.length + e.clipboardData.getData('Text').length > inputCharacterLimit) {
      e.preventDefault();
      e.stopPropagation();
    }
  }
  if (e.target.value.length >= inputCharacterLimit) {
    e.preventDefault();
    e.stopPropagation();
  }
};

const helperText = (props: { textLength: number; maxCharacters: number }) => {
  const diff = props.maxCharacters - props.textLength;
  if (props.textLength >= props.maxCharacters) {
    return 'Max character count reached.';
  }
  if (diff <= 30) {
    return `${diff} Characters remaining`;
  }
  return '';
};

const EmailBody = ({
  emailBody,
  handleChange,
  emailConfig,
  onFocus,
  onBlur,
  fullScreen,
  hideLabel,
}: {
  emailBody: string;
  handleChange: any;
  emailConfig: IEmailConfig;
  onFocus: any;
  onBlur: any;
  fullScreen: boolean;
  hideLabel: boolean;
}) => {
  const classes = useStyles();
  const { loading: smartEmailLoading } = useSmartEmailContext();
  const isMobileApp = isSkySlopeMobileApp();

  return (
    <div className="max-h-[399px] mb-[200px] md:mb-0">
      <div className="relative">
        {smartEmailLoading && <EmailBodyLoading />}
        <TextField
          label={hideLabel ? undefined : 'Email Message'}
          rowsMax={5}
          rows={5}
          inputProps={{
            style: {
              height: fullScreen ? '260px' : '342px',
              marginTop: '7px',
              fontSize: '22px',
            },
          }}
          InputLabelProps={{ className: 'pb-2' }}
          variant="filled"
          multiline
          type="text"
          data-spec="editableBody"
          placeholder={generateEmailBody()}
          onKeyPress={(e) => handleCharacterLimit(e, EMAIL_BODY_LENGTH)}
          onPaste={(e) => handleCharacterLimit(e, EMAIL_BODY_LENGTH)}
          className="pb-7.5 w-full"
          error={emailBody.length >= EMAIL_BODY_LENGTH - 30}
          helperText={helperText({ textLength: emailBody.length, maxCharacters: EMAIL_BODY_LENGTH })}
          FormHelperTextProps={{ classes: { root: classes.helperText } }}
          onChange={(e) => handleChange(e.target.value)}
          value={emailBody}
          disabled={emailConfig.loading}
          onFocus={onFocus}
          onBlur={onBlur}
        />
      </div>
      {!isMobileApp &&
        <div className="flex flex-grow gap-3 py-3">
          <div><InfoIcon className="w-[18px] h-[18px] fill-white stroke-grey-700 " /></div>
          <div className="text-grey-700">Powered by AI, please check responses.</div>
        </div>
      }
    </div>
  );
};

const EmailSubject = ({
  emailSubject,
  handleChange,
  emailConfig,
  onFocus,
  onBlur,
}: {
  emailSubject: string;
  handleChange: any;
  emailConfig: IEmailConfig;
  onFocus: any;
  onBlur: any;
}) => {
  const classes = useStyles();
  return (
    <div>
      <TextField
        label="Email Subject Line"
        InputProps={{ className: classes.subject }}
        variant="filled"
        type="text"
        data-spec="editableSubject"
        placeholder={generateEmailSubject()}
        onKeyPress={(e) => handleCharacterLimit(e, EMAIL_SUBJECT_LENGTH)}
        onPaste={(e) => handleCharacterLimit(e, EMAIL_SUBJECT_LENGTH)}
        className={classes.inputContent}
        error={emailSubject.length >= EMAIL_SUBJECT_LENGTH - 30}
        helperText={helperText({ textLength: emailSubject.length, maxCharacters: EMAIL_SUBJECT_LENGTH })}
        FormHelperTextProps={{ classes: { root: classes.helperText } }}
        onChange={(e) => handleChange(e.target.value)}
        value={emailSubject}
        disabled={emailConfig.loading}
        onFocus={onFocus}
        onBlur={onBlur}
      />
    </div>
  );
};

export const EditEmailModal = (props: IProps) => {
  const classes = useStyles();
  const { emailConfig, id, blocks, blockGroups, zoom, hasAttachment }: IEmailSettingsState =
    useShallowSelector(emailSettingsSelector);
  const [emailBody, setEmailBody] = useState('');
  const [emailSubject, setEmailSubject] = useState('');
  const dispatch = useDispatch();
  const isMobile = isMediumScreenMediaQuery();
  const toggleModalProp = props.toggleModal !== undefined;
  const isMobileApp = isSkySlopeMobileApp();
  const showSmartEmail = props.flags && props.flags['show-smart-email'];
  const { mutateAsync: updateEnvelopesLeft } = useUpdateEnvelopesLeft();

  useEffect(() => {
    if (isMobileApp) {
      postWindowMessage({
        header_text: showSmartEmail ? 'Save time with smart emails' : 'Ready to send?',
        back_button_type: '<',
        should_back_button_exit: false,
        button_text: 'Send',
      });
    }
    window.mobileApp.nextButtonTap = () => {};
    window.mobileApp.backButtonTap = () => {};
  }, []);

  window.mobileApp.backButtonTap = () => {
    if (props.handleBackButtonTap != undefined) props.handleBackButtonTap();
  };

  window.mobileApp.nextButtonTap = () => {
    if (props.sendEnvelopeForMobileApp != undefined)
      props.sendEnvelopeForMobileApp({ subject: emailSubject.trim() || '', body: emailBody.trim() || '' });
  };

  async function saveEmailConfigAndSometimesSend() {
    const sendEnvelope = props.send;
    if (sendEnvelope) {
      const updatedBlocksAndGroups = validateBlocksAndSendEnvelope(blocks, blockGroups, sendEnvelope, zoom);
      if (updatedBlocksAndGroups) {
        dispatch(
          validateBlocksBeforeSending(
            updatedBlocksAndGroups.updatedBlocks,
            updatedBlocksAndGroups.updatedGroups,
            { subject: emailSubject.trim() || '', body: emailBody.trim() || '', hasAttachment: hasAttachment ?? false },
            sendEnvelope
          )
        );
        await updateEnvelopesLeft();
      }
    } else {
      dispatch(
        updateEmailConfig(id, { subject: emailSubject.trim() || '', body: emailBody.trim() || '' }, sendEnvelope)
      );
    }
    if (toggleModalProp) {
      props.toggleModal?.();
    }
  }

  const onFocus = () => dispatch(focusInput());
  const onBlur = () => dispatch(blurInput());

  useEffect(() => {
    setEmailSubject(emailConfig.subject || '');
    setEmailBody(emailConfig.body || '');
  }, []);

  const modalTitleClass = isMobile ? classes.mobileTitle : '';
  return (
    <SmartEmailContextProvider>
      <Dialog
        open
        onClose={toggleModalProp ? props.toggleModal : () => ({})}
        classes={{ paper: `${isMobile ? '' : 'max-w-[824px]'} px-4 md:px-10 overflow-y-auto` }}
        // @ts-ignore
        PaperProps={{ 'data-spec': 'editEmailModal' }}
        maxWidth="lg"
        fullWidth
        fullScreen={props.fullScreen}
        BackdropProps={{ style: { backgroundColor: '#3f5b77', opacity: 0.6 } }}
      >
        <div className="flex justify-between items-center py-4 md:py-10">
          {props.subject && (
            <Typography classes={{ h3: modalTitleClass }} variant="h3">
              {props.subject}
            </Typography>
          )}
          {!isMobile && !isMobileApp && (
            <IconButton
              data-spec="close"
              onClick={toggleModalProp ? props.toggleModal : () => ({})}
              className="text-grey-500"
            >
              <CloseIcon />
            </IconButton>
          )}
        </div>
        <EmailSubject
          emailSubject={emailSubject}
          handleChange={setEmailSubject}
          emailConfig={emailConfig}
          onFocus={onFocus}
          onBlur={onBlur}
        />
        {showSmartEmail && (
          <SmartEmail emailBody={emailBody} setEmailBody={setEmailBody} envelopeId={props.envelopeId} />
        )}
        <EmailBody
          emailBody={emailBody}
          handleChange={setEmailBody}
          emailConfig={emailConfig}
          onFocus={onFocus}
          onBlur={onBlur}
          fullScreen={props.fullScreen}
          hideLabel={showSmartEmail}
        />
        {!isMobileApp && (
          <ActionButtons
            toggleModal={toggleModalProp ? props.toggleModal : () => ({})}
            action={props.action ? props.action : ''}
            saveEmailConfig={saveEmailConfigAndSometimesSend}
            emailConfig={emailConfig}
            fullScreen={isMobile}
          />
        )}
      </Dialog>
    </SmartEmailContextProvider>
  );
};

export default EditEmailModal;
